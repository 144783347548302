<template>
  <template v-if="reg">
    <img
      v-if="reg.logo"
      class="logo"
      :alt="$t('ui.logo')"
      :src="require(`@/assets/${reg.logo}`)"
    >
    <h1 class="title">
      {{ reg.title }}
    </h1>
    <p v-html="reg.description" />
    <TheRegistrationForm :reg="reg" />
  </template>
  <BaseInfobox
    v-else-if="errorMsg"
    type="error"
    remove-margins
    v-html="errorMsg"
  />
</template>

<script>
import TheRegistrationForm from '@/components/TheRegistrationForm.vue'
import BaseInfobox from '@/components/BaseInfobox.vue'
import RegistrationService from '@/services/RegistrationService.js'
import { setLocale } from '@/util/i18n.js'

export default {
  name: 'RegistrationPage',
  components: {
    TheRegistrationForm,
    BaseInfobox
  },
  data () {
    return {
      reg: null,
      errorMsg: ''
    }
  },
  async created () {
    setLocale(this.$route.query.lang)

    const params = this.$route.params
    const response = await RegistrationService.getReg(params.regId)
    this.reg = response.reg
    if (this.reg) {
      this.reg.email = params.email
    }
    this.errorMsg = response.errorMsg ? this.$t(response.errorMsg) : ''

    /* TODO: Falls inaktiv, Fehler von API liefern
             und bei Übermittlung in API "aktiv" prüfen */
    if (this.reg && !this.reg.active) {
      this.reg = null
      this.errorMsg = this.$t('clientError.regInactive')
    }
  }
}
</script>

export default {
  "ui": {
    "acceptPp": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ich habe die <a href=\"", _interpolate(_named("ppUrl")), "\" target=\"_blank\">Datenschutzerklärung</a> gelesen und verstanden und bin damit einverstanden."])},
    "acceptTac": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ich akzeptiere die <a href=\"", _interpolate(_named("tacUrl")), "\" target=\"_blank\">allgemeinen Geschäftsbedingungen</a>."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
    "operationSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorgang erfolgreich"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird verarbeitet..."])},
    "regCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierungscode"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])},
    "repeatPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])},
    "savePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort speichern"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])},
    "sendAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nochmals senden"])},
    "sendLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link senden"])}
  },
  "clientError": {
    "linkInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Link"])},
    "operationFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorgang fehlgeschlagen"])},
    "pwdsDontMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwörter stimmen nicht überein."])},
    "pwdTooShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort muss mindestens 8 Zeichen enthalten."])},
    "regInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Registrierung ist inaktiv. Entweder wurde sie noch nicht aktiviert oder sie ist bereits abgelaufen."])},
    "useEntireLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte verwenden Sie den vollständigen Link, den Sie erhalten haben."])},
    "veriFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifizierung fehlgeschlagen"])}
  },
  "serverMsg": {
    "mailResent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Die E-Mail wurde nochmals versendet.</strong> Der Link ist 30 Minuten gültig. Sollte die E-Mail nicht in Ihrem Posteingang erscheinen, prüfen Sie bitte auch Ihren Spamordner.<br><br>Sie können dieses Tab jetzt schliessen."])},
    "newpassDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte erstellen Sie ein neues, sicheres Passwort für Ihren Zugang."])},
    "regMailSent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<strong>Vielen Dank für Ihre Registrierung!</strong> Wir haben Ihnen eine E-Mail an die Adresse <strong>", _interpolate(_named("email")), "</strong> gesendet. Bitte folgen Sie dem in der E-Mail enthaltenen Link, um die Registrierung abzuschliessen. Der Link ist 30 Minuten lang gültig.<br>Sollten Sie die E-Mail nicht erhalten haben, überprüfen Sie bitte, ob Sie die korrekte Adresse eingetragen haben und schauen Sie in Ihrem Spamordner nach.<br>E-Mail <a href=\"/resend/verification-mail/", _interpolate(_named("regId")), "/", _interpolate(_named("email")), "?lang=", _interpolate(_named("lang")), "\">nochmals senden</a>?<br><br>Sie können dieses Tab jetzt schliessen."])},
    "resetDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte tragen Sie Ihre E-Mail-Adresse ein. Wir senden Ihnen einen Link, mit dem Sie ein neues Passwort erstellen können."])},
    "resetMailSent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<strong>Die E-Mail wurde versendet!</strong> Wir haben Ihnen eine E-Mail an die Adresse <strong>", _interpolate(_named("email")), "</strong> gesendet. Bitte folgen Sie dem in der E-Mail enthaltenen Link, um ein neues Passwort zu erstellen. Der Link ist 30 Minuten lang gültig.<br>Sollten Sie die E-Mail nicht erhalten haben, überprüfen Sie bitte, ob Sie die korrekte Adresse eingetragen haben und schauen Sie in Ihrem Spamordner nach.<br>E-Mail <a href=\"/resend/reset-mail/", _interpolate(_named("appId")), "/", _interpolate(_named("email")), "?lang=", _interpolate(_named("lang")), "\">nochmals senden</a>?<br><br>Sie können dieses Tab jetzt schliessen."])},
    "resetSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Ihr Passwort wurde geändert.</strong>"])},
    "veriSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Die Verifizierung war erfolgreich.</strong>"])}
  },
  "serverError": {
    "accountAlreadyUsed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Es besteht schon eine Registrierung mit dieser E-Mail-Adresse. Passwort <a href=\"/reset/id/", _interpolate(_named("appId")), "?lang=", _interpolate(_named("lang")), "\">zurücksetzen</a>?"])},
    "invalidData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden ungültige Daten übermittelt."])},
    "invalidRegCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Ungültiger Registrierungscode.</strong> Es gibt keine Registrierungsseite, die mit diesem Code verbunden ist."])},
    "invalidRegLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Ungültiger Registrierungslink.</strong> Bitte achten Sie darauf, dass Sie den ganzen Link kopieren."])},
    "invalidRegPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Ungültige Registrierungsseite.</strong> Falls Sie einen Link angeklickt haben, der Sie hierher weitergeleitet hat, dann wenden Sie sich bitte an den Support."])},
    "invalidResetLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Ungültiger Resetlink.</strong> Bitte achten Sie darauf, dass Sie den ganzen Link kopieren. Eventuell haben Sie Ihr Passwort bereits geändert oder der Resetlink ist abgelaufen."])},
    "invalidResetPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Ungültige Resetseite.</strong> Falls Sie einen Link angeklickt haben, der Sie hierher weitergeleitet hat, dann wenden Sie sich bitte an den Support."])},
    "invalidVeriLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Ungültiger Verifizierungslink.</strong> Bitte achten Sie darauf, dass Sie die ganze URL kopieren. Eventuell haben Sie Ihre Registrierung bereits verifiziert oder der Verifizierungslink ist abgelaufen."])},
    "noAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es existiert kein Benutzerkonto mit dieser E-Mail-Adresse."])},
    "noPendingReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurde kein ausstehender Reset gefunden. Entweder ist er bereits abgelaufen oder wurde schon durchgeführt."])},
    "noPendingVeri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurde keine ausstehende Verifizierung gefunden. Entweder ist diese bereits abgelaufen oder wurde schon durchgeführt."])},
    "notOnAllowList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre E-Mail-Adresse steht nicht auf der Liste der erlaubten Adressen. Bitte verwenden Sie dieselbe E-Mail-Adresse an welche die Registrierungseinladung gesendet wurde."])},
    "resetLinkAlreadySent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Es wurde bereits eine E-Mail mit dem Resetlink versendet. E-Mail <a href=\"/resend/reset-mail/", _interpolate(_named("appId")), "/", _interpolate(_named("email")), "?lang=", _interpolate(_named("lang")), "\">nochmals senden</a>?"])},
    "unknownServerError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannter Serverfehler. Bitte versuchen Sie es nochmals. Sollte der Fehler weiterhin bestehen, wenden Sie sich bitte an den Support."])},
    "veriLinkAlreadySent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Es wurde bereits eine E-Mail mit dem Verifizierungslink versendet. E-Mail <a href=\"/resend/verification-mail/", _interpolate(_named("regId")), "/", _interpolate(_named("email")), "?lang=", _interpolate(_named("lang")), "\">nochmals senden</a>?"])},
    "waitEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte warten Sie mindestens eine Minute, bis Sie die E-Mail erneut senden."])},
    "waitRegCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte warten Sie mindestens 30 Sekunden, bis Sie den Registrierungscode erneut senden."])}
  }
}